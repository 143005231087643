// eslint-disable-next-line import/no-unresolved
import { useLocation } from '@reach/router';

import { getCurrentLocaleFromPathname, LocaleEnum } from '../utils/i18n';

/**
 * Custom hook to retrieve the locale from reach router
 * @param {string} [pathname] pathname to extract the locale from
 * @returns {LocaleEnum}
 */
export function useLocaleFromRouter(): LocaleEnum {
  return getCurrentLocaleFromPathname(useLocation().pathname);
}
