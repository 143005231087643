import { isSafari } from './browser';

export function setCookie(
  name: string,
  value: string,
  expirationDate?: string
): void {
  if (typeof expirationDate === 'undefined') {
    const oneYearFromNow = new Date();
    oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
    expirationDate = oneYearFromNow.toUTCString();
  }

  document.cookie = `${name}=${value}; expires=${expirationDate}; path=/; ${
    isSafari() ? 'samesite=lax; secure' : 'samesite=strict; secure'
  }`;
}

export function getCookie(name: string): string | null {
  const cookiesString = `; ${document.cookie}`;

  if (cookiesString === '; ') {
    return null;
  }

  const keyStartIndex = cookiesString.indexOf(`; ${name}=`);

  if (keyStartIndex !== -1) {
    const cookieStartIndex = keyStartIndex + name.length + 3;
    const cookieEndIndex = cookiesString.indexOf(';', cookieStartIndex);

    const cookie = cookiesString.slice(
      cookieStartIndex,
      cookieEndIndex !== -1 ? cookieEndIndex : cookiesString.length
    );

    return cookie;
  }

  return null;
}
