import { navigate } from 'gatsby-link';
import { useEffect } from 'react';

import { getCookie } from '../utils/cookies';
import { getLocale, getLocaleDefault, LocaleType } from '../utils/i18n';

/**
 * Custom hook to redirect client to preferred language homepage
 * @returns {null}
 */
export function useRedirectToHomepage() {
  useEffect(() => {
    let locale: LocaleType;
    const localeCookie = <LocaleType>getCookie('locale');
    const navigatorLocale = <LocaleType>navigator?.language?.split('-')[0];

    if (localeCookie) {
      locale = getLocale(localeCookie);
    } else if (navigatorLocale) {
      locale = getLocale(navigatorLocale);
    } else {
      locale = getLocaleDefault();
    }

    navigate(`/${locale}`);
  }, []);

  return null;
}
