import { useLocaleFromRouter } from '../hooks/useLocaleFromRouter';
import { OrUndefined } from '../types/general';

import { ROUTE_REGEX } from './regexes';

export enum LocaleEnum {
  fr = 'fr',
  en = 'en',
}

const Locales = [LocaleEnum.fr, LocaleEnum.en] as const;

export function getLocaleDefault(): LocaleEnum {
  return LocaleEnum.en;
}

export function getLocale(locale: LocaleType) {
  return LocaleEnum[locale];
}

export function getLocales(): typeof Locales;
export function getLocales(exclude: LocaleEnum[]): LocaleEnum[];
export function getLocales(
  exclude: LocaleEnum[] = []
): typeof Locales | LocaleEnum[] {
  if (exclude.length) {
    return Locales.filter((locale) => !exclude.includes(locale));
  }

  return Locales;
}

export type LocaleType = keyof Omit<typeof LocaleEnum, 'default'>;

export type Translation = {
  key: string;
  locale: string;
  message: string;
};

export type I18n = {
  locale: string;
  translations: Translation[];
  readonly t: (key: string, forceLocale?: LocaleEnum) => string;
};

function translate(
  key: string,
  translations: Translation[],
  locale: LocaleType
): string {
  const translation = translations.find((t) =>
    t.key === key && t.locale === locale);

  if (!translation) {
    console.warn(`Missing translation '${key}' for locale '${locale}'`);
  }

  return translation?.message || '';
}

export function getCurrentLocaleFromPathname(pathname: string): LocaleEnum {
  const locale = <LocaleType>pathname.split(`/`)[1];

  return getLocale(locale) ?? getLocaleDefault();
}

export function changePathnameLocale(
  pathname: string,
  toLocale: LocaleType
): OrUndefined<string> {
  const match = pathname.match(ROUTE_REGEX);

  if (match) {
    return `/${toLocale}${match[2] || ''}`;
  }
}

export function useI18n(translations: Translation[]): I18n {
  const locale = useLocaleFromRouter();

  return {
    locale,
    translations,
    t: function t(key: string, forceLocale?: LocaleEnum) {
      return translate(key, translations, forceLocale ? forceLocale : locale);
    },
  };
}
